<template>
  <div
    class="medication-item"
    v-if="item"
    :id="`MedicationDetail_${item.slug}`"
  >
    <div class="medication-item__image-container">
      <img
        class="medication-item__image"
        :src="item.image"
        @click="
          toMediDetail({
        name: 'DrugsTemplates',
        params: { medication: item.slug },
      })
        "
      />
      <Favorite
        class="medication-item__favorite"
        :watchLater="item.watch_later"
        :favorite="item.favorite"
        @watchLater="$emit('watchLater')"
        @favorite="$emit('favorite')"
        v-if="!hideFavorites"
      />
    </div>
    <div class="medication-item__categories">
      <div
        class="medication-item__category mr-2"
        v-for="tag in item.nosology"
        :key="tag.id"
      >
        {{ tag.title }}
      </div>
    </div>
    <div
      class="medication-item__text-container"
      @click="
       toMediDetail({
        name: 'DrugsTemplates',
        params: { medication: item.slug },
      })
      "
    >
      <div class="medication-item__text">
        <div class="medication-item__title" v-html="item.name"></div>
        <div class="medication-item__main-ingredient" v-html="item.substance">
        </div>
      </div>
      <div class="medication-item__link d-none d-xl-flex">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 8.00012L9 16.0001L6.5 16.0001L12.5 9.00012L-3.0598e-07 9.00012L-3.93402e-07 7.00012L12.5 7.00012L6.5 0.000121786L9 0.000121677L16 8.00012Z"
            fill="#830051"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Favorite from "./Favorite.vue";
export default {
  components: { Favorite },
  name: "Medication",
  props: {
    item: Object,
    hideFavorites: { type: Boolean, default: false },
  },
  computed: {},
  methods: {
    toMediDetail(to, params) {
      const vm = this; 
      this.$emit('toMediDetail', this.item.name)
      this.$router.push(to, params).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.medication-item {
  cursor: pointer;
  border: 1px solid #d2d2d2;

  &:hover .medication-item__title {
    color: #d0006f;
  }

  &__favorite {
    position: absolute;
    bottom: 0;
    right: 30px;
    z-index: 10;
  }

  &__link {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ebefee;
    color: #830051;
    cursor: pointer;
    transition: 0.3s;
    flex-shrink: 0;
    margin-left: 24px;

    &:hover {
      background-color: #d8dfde;
    }
  }

  &__text {
    width: 100%;
  }

  &__text-container {
    padding: 0 24px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__image-container {
    position: relative;
    padding: 10px 0 24px;
    width: 100%;
    height: 210px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__title {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    transition: 0.3s;

    @media screen and (max-width: 1220px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__main-ingredient {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #656969;
  }

  &__categories {
    padding: 0 24px;
    margin-bottom: 16px;
  }

  // &__category {
  //   margin-bottom: 4px;
  //   padding: 4px 8px;
  //   display: inline-block;
  //   font-family: "Roboto", sans-serif;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 10px;
  //   line-height: 13px;
  //   color: #3c4242;
  //   border: 1px solid #d2d2d2;
  //   border-radius: 999px;
  // }
  
  &__category {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3C4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>